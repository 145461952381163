@import '../mixins/responsive.scss';
@import '../base/colors.scss';

div[class*='header'] {
  .wrapper_header_content {
    @include responsive(desktop) {
      justify-content: center;
    }
    .title {
      @include responsive(desktop) {
        text-align: center !important;
      }
      .heading_white_word {
        background-image: none;
        background-clip: inherit;
        -webkit-background-clip: inherit;
        -webkit-text-fill-color: white;
        -webkit-text-fill-color: white;
      }
    }
    @include responsive(tablet) {
      max-width: 1000px;
    }
    @include responsive(desktop) {
      max-width: 1000px;
    }
    @include responsive(extra-desktop) {
      max-width: 1200px;
    }

    .header_description {
      @include responsive(tablet) {
        text-align: left;
      }
    }
  }
  .header_cta {
    @include responsive(desktop) {
      justify-content: center !important;
    }
    @include responsive(extra-desktop) {
      max-width: 1200px;
    }
  }
}
