@import '../../sass/mixins/responsive.scss';

.pricing_title {
  @include responsive(desktop) {
    text-align: center !important;
    .title_for_you {
      line-height: 1.1em;
    }
  }
  .heading_white_word {
    background-image: none;
    background-clip: inherit;
    -webkit-background-clip: inherit;
    -webkit-text-fill-color: white;
    -webkit-text-fill-color: white;
  }
}

.pricing_description_page {
  @include responsive(tablet) {
    text-align: center !important;
    width: 90%;
  }
}

.pricing_price {
  font-size: 6em;
  @include responsive(tablet) {
    text-align: center !important;
  }
  @include responsive(desktop) {
    text-align: left !important;
  }
}

.pricing_wrapper_pricing {
  @include responsive(tablet) {
    padding: 0em 1em;
  }
  .pricing_wrapper_sub_pricing {
    @include responsive(desktop) {
      flex-direction: row;
    }
    .pricing_illustration {
      background-image: url('../../assets/illustrations/illustration_pricing.png');
      background-size: contain;
      background-position: bottom;
      background-repeat: no-repeat;
      width: 100%;
      @include responsive(tablet) {
        min-height: 500px;
      }
      @include responsive(desktop) {
        min-height: 600px;
      }
      @include responsive(extra-desktop) {
        object-fit: contain;
      }
    }
    .pricing_wrapper_left {
      @include responsive(tablet) {
        padding-bottom: 1em;
        margin-top: 2em;
      }
      @include responsive(desktop) {
        padding: 3em 4.3em;
      }
      .pricing_wrapper_price_details {
        @include responsive(desktop) {
          max-width: 100%;
        }
        @include responsive(extra-desktop) {
          max-width: 90%;
          padding-left: 8em;
        }
      }
      .pricing_box_cta {
        @include responsive(tablet) {
          justify-content: center !important;
        }
        @include responsive(desktop) {
          justify-content: flex-start !important;
          align-items: flex-start;
          padding: 0;
          margin-top: 0;
        }
      }
    }
  }
  .pricing_description_title {
    margin-bottom: 0.5em;
    @include responsive(tablet) {
      text-align: center !important;
    }
    @include responsive(desktop) {
      text-align: left !important;
    }
  }
  .pricing_price_description {
    width: 100%;
    @include responsive(tablet) {
      text-align: center !important;
    }
    @include responsive(desktop) {
      text-align: left !important;
    }
  }
}
