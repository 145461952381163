/* Définition des couleurs */

$color-primary: #ffffff;
$color-secondary: #000000;
$color-tertiary: rgb(226, 226, 226);
$color-ecology: #5fd39b;
$color-success: #17a2b8;
$color-warning: #ffc107;
$color-error: #dc3545;

/* Définition des classes de couleur */

.color {
  &--primary {
    color: $color-primary;
  }

  &--secondary {
    color: $color-secondary;
  }

  &--tertiary {
    color: $color-tertiary;
  }

  &--ecology {
    color: $color-ecology;
  }

  &--success {
    color: $color-success;
  }

  &--warning {
    color: $color-warning;
  }

  &--error {
    color: $color-error;
  }
  &--rainbow {
    color: white;
    background-image: -webkit-linear-gradient(
      0deg,
      #c2359c 6%,
      #d59240 38%,
      #dcb641 49%,
      #58c986 65%,
      #3083bf 74%,
      #7b46a1 86%,
      #cb2da1 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

/* Définition des classes d'arrière-plan */

.background {
  &--primary {
    background-color: $color-primary;
  }

  &--secondary {
    background-color: $color-secondary;
  }

  &--tertiary {
    background-color: $color-tertiary;
  }

  &--ecology {
    background-color: $color-ecology;
  }

  &--success {
    background-color: $color-success;
  }

  &--warning {
    background-color: $color-warning;
  }

  &--error {
    background-color: $color-error;
  }
  &--rainbow {
    background-image: -webkit-linear-gradient(
      0deg,
      #c2359c1f 6%,
      #d59240 38%,
      #dcb641 49%,
      #58c986 65%,
      #3083bf 74%,
      #7b46a1 86%,
      #cb2da130 100%
    );
  }
}
