@import '../mixins/responsive.scss';

@font-face {
  font-family: 'Gotham-black';
  src: url('../../assets/fonts/gotham/Gotham-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-bold';
  src: url('../../assets/fonts/gotham/Gotham-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-light';
  src: url('../../assets/fonts/gotham/GothamLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-medium';
  src: url('../../assets/fonts/gotham/GothamMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-black';
  src: url('../../assets/fonts/poppins/Poppins-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-bold';
  src: url('../../assets/fonts/poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-extrabold';
  src: url('../../assets/fonts/poppins/Poppins-ExtraBold.ttf')
    format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-semibold';
  src: url('../../assets/fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

/* Classes pour la police Gotham */
.heading--ultralarge {
  font-family: 'Gotham-black';
  font-size: 50px;
  width: 100%;
  line-height: 4.5rem;
  hyphens: auto !important;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  @include responsive(tablet) {
    font-size: 4rem;

    line-height: 4.5rem;
    letter-spacing: -4px;
  }
  @include responsive(desktop) {
    font-size: 6.7rem;
    letter-spacing: -4px;
    line-height: 7.2rem;
    text-align: center !important;
  }
}

.heading--extralarge {
  font-family: 'Gotham-black';
  font-size: 50px;
  width: 100%;
  line-height: 4.5rem;
  hyphens: auto !important;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  @include responsive(tablet) {
    font-size: 4.2rem;
    letter-spacing: -4px;
    line-height: 5rem;
    text-align: center !important;
  }
  @include responsive(desktop) {
    font-size: 5rem;
    letter-spacing: -4px;
    line-height: 5rem;
    text-align: center !important;
  }
}

.heading {
  &--large {
    font-family: 'Gotham-bold', sans-serif;
    font-size: 2.5rem;
    line-height: 70px;
    letter-spacing: -2px;
    @include responsive(tablet) {
      font-size: 2.5rem;
      line-height: 70px;
      letter-spacing: -2px;
    }
    &--light {
      font-family: 'Poppins-semibold', sans-serif;
      font-size: 2.5rem;
      line-height: 70px;
      letter-spacing: -2px;
      @include responsive(tablet) {
        font-size: 2.5rem;
        line-height: 70px;
        letter-spacing: -2px;
      }
    }
    &--extralight {
      font-family: 'Gotham-light', sans-serif;
      font-size: 2.5rem;
      line-height: 70px;
      letter-spacing: -2px;
      @include responsive(tablet) {
        font-size: 4rem;
        line-height: 70px;
        letter-spacing: -2px;
      }
    }
  }
}

.heading--medium {
  font-family: 'Gotham-bold', sans-serif;
  font-size: 1.7rem;
  line-height: 50px;
  letter-spacing: 1px;
  @include responsive(tablet) {
    font-size: 2rem;
    line-height: 50px;
    letter-spacing: 1px;
  }
  &--light {
    font-family: 'Poppins-semibold', sans-serif;
    font-size: 2rem;
    line-height: 50px;
    letter-spacing: -2px;
    @include responsive(tablet) {
      font-size: 2rem;
      line-height: 50px;
      letter-spacing: 1px;
    }
  }
  &--extralight {
    font-family: 'Gotham-light', sans-serif;
    font-size: 2rem;
    line-height: 50px;
    letter-spacing: -2px;
    @include responsive(tablet) {
      font-size: 2rem;
      line-height: 50px;
      letter-spacing: -2px;
    }
  }
}

.heading--small {
  font-family: 'Gotham-light', sans-serif;
  font-size: 1.5rem;
  line-height: 50px;
  letter-spacing: -2px;
  &--light {
    font-family: 'Poppins-semibold', sans-serif;
    font-size: 1.5rem;
    line-height: 50px;
    letter-spacing: -2px;
  }
  &--extralight {
    font-family: 'Gotham-light', sans-serif;
    font-size: 1.5rem;
    line-height: 50px;
    letter-spacing: -2px;
  }
}

/* Classes pour la police Poppins */
.text--large {
  font-family: 'Poppins-black', sans-serif;
  font-size: 1.5rem;
  line-height: 50px;
  @include responsive(tablet) {
    font-size: 2rem;
    line-height: 50px;
  }
  &--light {
    font-family: 'Poppins-semibold', sans-serif;
    font-size: 1.5rem;
    line-height: 50px;
    @include responsive(tablet) {
      font-size: 2rem;
      line-height: 50px;
    }
  }
  &--extralight {
    font-family: 'Gotham-light', sans-serif;
    font-size: 1.5rem;
    line-height: 50px;
    @include responsive(tablet) {
      font-size: 2rem;
      line-height: 50px;
    }
  }
}

.text--medium {
  font-family: 'Poppins-bold', sans-serif;
  font-size: 1.2rem;
  line-height: 50px;
  letter-spacing: 1px;
  &--light {
    font-family: 'Poppins-semibold', sans-serif;
    font-size: 1.2rem;
    line-height: 50px;
    letter-spacing: 1px;
  }
  &--extralight {
    font-family: 'Gotham-light', sans-serif;
    font-size: 1.2rem;
    line-height: 50px;
    letter-spacing: 1px;
    @include responsive(desktop) {
      text-align: center !important;
    }
  }
}

.text--small {
  font-family: 'Poppins-semibold', sans-serif;
  font-size: 1.1rem;
  line-height: 40px;
  &--light {
    font-family: 'Gotham-light', sans-serif;
    font-size: 1rem;
    line-height: 40px;
    @include responsive(desktop) {
      font-size: 1.1rem;
      line-height: 40px;
    }
  }
}

.dot {
  font-size: 5rem;
  font-family: 'Poppins-black', sans-serif;
  margin-left: 0.3rem;
}

.emoji {
  font-size: 5rem;
}

/* Classes pour le style de texte */
.underline {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}

/* Classes pour le style de police */
.serif {
  font-family: Georgia, 'Times New Roman', serif;
}

.sans-serif {
  font-family: 'Arial', 'Helvetica Neue', sans-serif;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.hyphens {
  hyphens: auto;
}

.logo {
  &--nav {
    height: 20px;
  }
  &--footer {
    height: 22px;
  }
}

.icon {
  &--footer {
    height: 30px;
  }
}
