@import '../mixins/responsive.scss';

.flexbox {
  display: flex;
  &.justify-flex-start {
    justify-content: flex-start;
  }
  &.justify-flex-end {
    justify-content: flex-end;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-space-between {
    justify-content: space-between;
  }
  &.justify-space-around {
    justify-content: space-around;
  }
  &.justify-space-evenly {
    justify-content: space-evenly;
  }
  &.align-stretch {
    align-items: stretch;
  }
  &.align-flex-start {
    align-items: flex-start;
  }
  &.align-flex-end {
    align-items: flex-end;
  }
  &.align-center {
    align-items: center;
  }
  &.align-baseline {
    align-items: baseline;
  }
  &.flex-row {
    flex-direction: row;
  }
  &.row-reverse {
    flex-direction: row-reverse;
  }
  &.column {
    flex-direction: column;
  }
  &.column-reverse {
    flex-direction: column-reverse;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.nowrap {
    flex-wrap: nowrap;
  }
  &.wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}

.bar {
  transform: rotate(90deg);
  margin: 5em 0;
  @include responsive(tablet) {
    margin: O 1em;
    transform: rotate(0deg);
  }
}

.separation {
  &--rainbow {
    height: 2px;
    background-color: -webkit-linear-gradient(
      0deg,
      #c2359c 6%,
      #d59240 38%,
      #dcb641 49%,
      #58c986 65%,
      #3083bf 74%,
      #7b46a1 86%,
      #cb2da1 100%
    );
  }
}
