.container--responsive {
  max-width: 1828px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

/* Définition des variables pour les marges et les paddings */

.m {
  &-0 {
    margin: 0;
  }

  &t-1 {
    margin-top: 1em;
  }
  &t-2 {
    margin-top: 2em;
  }
  &t-3 {
    margin-top: 3em;
  }
  &t-4 {
    margin-top: 4em;
  }
  &t-5 {
    margin-top: 5em;
  }
  &b-1 {
    margin-bottom: 1em;
  }
  &b-2 {
    margin-bottom: 2em;
  }
  &b-3 {
    margin-bottom: 3em;
  }
  &b-4 {
    margin-bottom: 4em;
  }
  &b-5 {
    margin-bottom: 5em;
  }
  &l-1 {
    margin-left: 1em;
  }
  &l-2 {
    margin-left: 2em;
  }
  &l-3 {
    margin-left: 3em;
  }
  &l-4 {
    margin-left: 4em;
  }
  &l-5 {
    margin-left: 5em;
  }
  &r-1 {
    margin-right: 1em;
  }
  &r-2 {
    margin-right: 2em;
  }
  &r-3 {
    margin-right: 3em;
  }
  &r-4 {
    margin-right: 4em;
  }
  &r-5 {
    margin-right: 5em;
  }
  &-1 {
    margin: 1em;
  }
  &-2 {
    margin: 2em;
  }
  &-3 {
    margin: 3em;
  }
  &-4 {
    margin: 4em;
  }
  &-5 {
    margin: 5em;
  }
  &-lr-1 {
    margin: 0 1em;
  }
  &-lr-2 {
    margin: 0 2em;
  }
  &-lr-3 {
    margin: 0 3em;
  }
  &-lr-4 {
    margin: 0 4em;
  }
  &-lr-5 {
    margin: 0 5em;
  }
  &-tb-1 {
    margin: 1em 0em;
  }
  &-tb-2 {
    margin: 2em 0em;
  }
  &-tb-3 {
    margin: 3em 0em;
  }
  &-tb-4 {
    margin: 4em 0em;
  }
  &-tb-5 {
    margin: 5em 0em;
  }
  &x-1 {
    margin: 1;
  }
  &x-2 {
    margin: 1;
  }
  &x-3 {
    margin: 1;
  }
  &x-4 {
    margin: 1;
  }
  &x-5 {
    margin: 1;
  }
  &x-auto {
    margin: auto;
  }
}

.p {
  &-0 {
    padding: 0;
  }

  &t-1 {
    padding-top: 1em;
  }
  &t-2 {
    padding-top: 2em;
  }
  &t-3 {
    padding-top: 3em;
  }
  &t-4 {
    padding-top: 4em;
  }
  &t-5 {
    padding-top: 5em;
  }
  &b-1 {
    padding-bottom: 1em;
  }
  &b-2 {
    padding-bottom: 2em;
  }
  &b-3 {
    padding-bottom: 3em;
  }
  &b-4 {
    padding-bottom: 4em;
  }
  &b-5 {
    padding-bottom: 5em;
  }
  &l-1 {
    padding-left: 1em;
  }
  &l-2 {
    padding-left: 2em;
  }
  &l-3 {
    padding-left: 3em;
  }
  &l-4 {
    padding-left: 4em;
  }
  &l-5 {
    padding-left: 5em;
  }
  &r-1 {
    padding-right: 1em;
  }
  &r-2 {
    padding-right: 2em;
  }
  &r-3 {
    padding-right: 3em;
  }
  &r-4 {
    padding-right: 4em;
  }
  &r-5 {
    padding-right: 5em;
  }
  &-1 {
    padding: 1em;
  }
  &-2 {
    padding: 2em;
  }
  &-3 {
    padding: 3em;
  }
  &-4 {
    padding: 4em;
  }
  &-5 {
    padding: 5em;
  }
  &-lr-1 {
    padding: 0 1em;
  }
  &-lr-2 {
    padding: 0 2em;
  }
  &-lr-3 {
    padding: 0 3em;
  }
  &-lr-4 {
    padding: 0 4em;
  }
  &-lr-5 {
    padding: 0 5em;
  }
  &-tb-1 {
    padding: 1em 0em;
  }
  &-tb-2 {
    padding: 2em 0em;
  }
  &-tb-3 {
    padding: 3em 0em;
  }
  &-tb-4 {
    padding: 4em 0em;
  }
  &-tb-5 {
    padding: 5em 0em;
  }
  &x-1 {
    padding: 1;
  }
  &x-2 {
    padding: 1;
  }
  &x-3 {
    padding: 1;
  }
  &x-4 {
    padding: 1;
  }
  &x-5 {
    padding: 1;
  }
  &x-auto {
    padding: auto;
  }
}

.border {
  &--radius {
    &-1 {
      border-radius: 10px;
    }
  }
}
