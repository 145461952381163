@import '../mixins/responsive.scss';

.nav {
  width: 100%;
  height: 70px;

  @include responsive(tablet) {
    height: 80px;
  }
  @include responsive(desktop) {
    padding: 0 3rem;
  }
  transition: 0.3s ease all;

  &_content {
    overflow: hidden;
    margin: 0 auto;
    max-width: 1920px;
    position: relative;
    &_nav {
      top: 0;
      right: 100%;
      bottom: 0;
      position: fixed;
      width: 100%;
      background: rgba(black, 0.9);
      backdrop-filter: blur(2px);
      transform: translate(0);
      transition: 0.3s ease transform;

      @include responsive(tablet) {
        transform: none;
        flex-direction: row;
        background: transparent;
        width: auto;
        height: 100%;
        position: static;
      }
      .links_box {
        list-style: none;
        padding: 0;
        @include responsive(tablet) {
          flex-direction: row;
          align-items: center;
          margin-bottom: 0;
          margin-right: calc(0.5rem + 16px);
        }
      }
      &.isMenu {
        transform: translate(100%);
        @include responsive(tablet) {
          transform: translate(0%);
        }
      }
    }

    &_toggle {
      cursor: pointer;
      font-size: 2rem;
      transition: 0.3s ease all;
      position: relative;
      @include responsive(tablet) {
        display: none;
      }
    }
  }
}
