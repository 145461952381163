@import '../../../sass/mixins/responsive.scss';

.switchLanguage_dropdown {
  height: 40px;
  width: 70px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.074);  font-size: 0.8em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  @include responsive(tablet) {
    font-size: 1.5em;
  }
  @include responsive(desktop) {
    font-size: 1.5em;
  }
}
