@import '../base/colors';
@import '../mixins/responsive.scss';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4rem;
  padding: 1rem;
  cursor: pointer;

  &--primary {
    background-color: white;
    border: 2px solid white;
    &-effect {
      background-color: white;
      text-shadow: none;
      transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 2);
      @include responsive(desktop) {
        box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
        outline: 1px solid;
        outline-color: rgba(255, 255, 255, 0.5);
        outline-offset: 0px;
        &:hover {
          border: none;
          box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
            0 0 20px rgba(255, 255, 255, 0.2);
          outline-color: rgba(255, 255, 255, 0);
          outline-offset: 15px;
        }
      }
    }
  }

  &--secondary {
    background-color: black;
    border: 2px solid white;
    &-effect {
      background-color: white;
      text-shadow: none;
      transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 2);
      @include responsive(desktop) {
        box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
        outline: 1px solid;
        outline-color: rgba(255, 255, 255, 0.5);
        outline-offset: 0px;
        &:hover {
          border: none;
          box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
            0 0 20px rgba(255, 255, 255, 0.2);
          outline-color: rgba(255, 255, 255, 0);
          outline-offset: 15px;
        }
      }
    }
  }

  &--tertiary {
    background-color: transparent;
    color: #0077cc;
    &-effect {
      background-color: white;
      text-shadow: none;
      transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 2);
      @include responsive(desktop) {
        box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
        outline: 1px solid;
        outline-color: rgba(255, 255, 255, 0.5);
        outline-offset: 0px;
        &:hover {
          border: none;
          box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
            0 0 20px rgba(255, 255, 255, 0.2);
          outline-color: rgba(255, 255, 255, 0);
          outline-offset: 15px;
        }
      }
    }
  }
  &--large {
    height: 80px;
    width: 260px;
  }
  &--medium {
    height: 60px;
    width: 210px;
  }
  &--small {
    height: 30px;
    width: 140px;
  }
}
