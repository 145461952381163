@import '../../../sass/mixins/responsive.scss';

div[class*='connectYourPost_wrapper'] {
  @include responsive(tablet) {
    width: 90% !important;
  }
  .connectYourPost_title {
    @include responsive(tablet) {
      text-align: center;
    }
  }
  .connectYourPost_description {
    @include responsive(tablet) {
      text-align: center;
    }
  }
  .connectYourPost_illustration {
    height: auto;
    width: 100%;
    @include responsive(tablet) {
      height: 500px;
    }
  }
}
