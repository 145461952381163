@import '../../../sass/mixins/responsive.scss';

div[class*='howItWorks_container_steps'] {
  @include responsive(tablet) {
    flex-direction: row !important;
  }
  .howItWorks_box_step {
    @include responsive(tablet) {
      flex-direction: row;
    }
    .howItWorks_separation {
      transform: rotate(90deg);
      @include responsive(tablet) {
        transform: rotate(0deg);
        margin: 0em 1em;
      }
    }
  }
}
