@import '../../../sass/mixins/responsive.scss';

.subHeader_wrapper {
  @include responsive(desktop) {
    width: 70% !important;
  }
  .subHeader_box_dontPlay {
    @include responsive(desktop) {
      justify-content: center;
      text-align: center;
    }
  }
  .subHeader_title {
    @include responsive(desktop) {
      text-align: center;
    }
  }
  .subHeader_description {
    @include responsive(desktop) {
      text-align: center;
    }
  }
}
