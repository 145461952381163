@import '../../../sass/mixins/responsive.scss';

div[class*='monetization_wrapper'] {
  @include responsive(tablet) {
    padding: 2em;
  }
  .monetization_left {
    @include responsive(desktop) {
      align-items: center;
      width: 60% !important;
      padding: 1em 4em;
    }
    @include responsive(extra-desktop) {
      width: 50% !important;
    }
    h2 {
      @include responsive(desktop) {
        width: 80%;
      }
    }
    span {
      @include responsive(desktop) {
        width: 80%;
      }
    }
  }
  .monetization_right {
    @include responsive(desktop) {
      width: 40% !important;
    }
    .monetization_illustration {
      height: auto;
      width: 100%;
      @include responsive(tablet) {
        height: 500px;
      }
    }
  }
}
