/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background-color: #000000;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  font-family: sans-serif;
}

/* Remove list styles */
ol,
ul {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A better looking default link */
a {
  color: inherit;
  text-decoration: none;
}
