@import '../../sass/mixins/responsive.scss';

.faq {
  .faq_title_for_you {
    @include responsive(desktop) {
      line-height: 1.1em;
    }
  }
  .faq_description_page {
    @include responsive(tablet) {
      max-width: 80%;
    }
    @include responsive(desktop) {
      max-width: 50%;
    }
  }
  .faq_cards_list_security {
    @include responsive(tablet) {
      padding: 0 3em;
    }
    @include responsive(desktop) {
      flex-direction: row;
    }
  }

  .faq_card {
    .box_check {
      padding-top: 0.5em;
    }
    .box_check {
      padding-top: 0.5em;
    }
  }

  .faq_cta_wrapper {
    max-width: 1000px;
  }
}
