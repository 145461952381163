$breakpoints: (
  'tablet': 768px,
  'desktop': 992px,
  'extra-desktop': 1200px,
);

@mixin responsive($key) {
  $value: map-get($breakpoints, $key);

  @if $value {
    @media (min-width: $value) {
      @content;
    }
  } @else {
    @error "Invalid breakpoint value for '#{$key}'";
  }
}
