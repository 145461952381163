@import '../mixins/responsive.scss';

.footer_wrapper {
  @include responsive(tablet) {
    min-height: 110px;
  }
  @include responsive(desktop) {
    padding-top: 2em;
    padding-bottom: 0em;
    padding-left: 2em;
    padding-right: 2em;
  }
  .footer_branding {
    @include responsive(tablet) {
      flex-direction: row;
      align-items: center;
    }
    @include responsive(desktop) {
      padding-top: 0;
    }
  }
  div[class*='footer_informations'] {
    @include responsive(tablet) {
      flex-direction: row;
      align-items: center;
    }
    .footer_informations_copyright {
      @include responsive(tablet) {
        width: 50%;
      }
      @include responsive(desktop) {
        padding-left: 0;
      }
    }
    .footer_informations_credentials {
      @include responsive(tablet) {
        width: 49%;
      }
      @include responsive(desktop) {
        padding-right: 0em;
      }
    }
  }
  p {
    @include responsive(tablet) {
      margin: 0;
    }
  }
}
