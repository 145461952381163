@import '../mixins/responsive.scss';

.accordion {
  border-bottom: 1px solid rgba(194, 194, 194, 0.164);
  padding: 1em;
  width: 90%;
  @include responsive(tablet) {
    max-width: 900px;
  }

  &__title {
    padding: 1rem 0.5rem;
    font-weight: 600;
    font-size: 1.2rem;
    cursor: pointer;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    span {
      width: 90%;
    }
  }

  &__icon {
    i {
      transition: 0.3s ease-in-out;
    }
  }

  &.active &__icon {
    i {
      transform: rotate(180deg);
      font-size: 1.5em;
    }
  }

  &__content {
    padding: 0 0.5rem;
    max-height: 0px;
    overflow: hidden;
    transition: 0.4s ease-in-out;
    text-align: left;
    span {
      color: rgb(226, 226, 226);
    }
  }

  &.active &__content {
    padding: 1.5rem 0.5rem;
    max-height: 9em;
  }
}
